import React, { useState } from "react";
//import Search from "../../assets/icons/svg/search.svg";
import Google_icon from "../../assets/icons/svg/google.svg";
import Eye_icon from "../../assets/icons/svg/eye-icon.svg";
import Cross_icon from "../../assets/icons/svg/cross-icon.svg";
import Hide_eye_icon from "../../assets/icons/svg/hide-icon.svg";
import styles from '../../Components/Login/login.module.css';
import { loginUser } from "../../services/TokenCreate";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode as jwt_decode } from "jwt-decode";


const Loginbox = ({ onPasswordSubmit }) => {
  const [email, setEmail] = useState("");
  const [activeField, setActiveField] = useState(null);
  const [inputValues, setInputValues] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);  
  const [error, setError] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    new_password: false,
    confirm_new_password: false
  });
  const storedClientId = process.env.REACT_APP_SECRET_CLIENT_ID;  

  const handleFocus = (field) => {
    setActiveField(field);
  };
  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [field]: !prev[field]
    }));
  };
  const handleGoogleSuccess = (response) => {
    const userObject = jwt_decode(response.credential); 
    console.log(userObject)
    if (userObject && userObject.email) {
      handlePasswordSubmitInternal(userObject.email);
    }
  };

  const handleGoogleError = () => {
    console.error("Google Login Failed");
  };
  const handlePasswordSubmitInternal = (email) => {
    localStorage.setItem("email", email);
    setEmail(email);
    onPasswordSubmit(email);
    // handleSessionExpiration();
  };

  const handleBlur = (field) => {
    if (!inputValues[field]) {
      setActiveField(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error state before attempting login
    try {
      // Call the loginUser function from your API service
      const access_token = await loginUser(inputValues.email, inputValues.password);      
      if (access_token) {
        // If login is successful, proceed
        onPasswordSubmit(inputValues.email);
      } else {
        // If login is unsuccessful, set an error message
        setError('Invalid email or password');
      }
    } catch (err) {
      // Handle exceptions and set an error message
      if (err.response && err.response.status === 401) {
        setError('Invalid email or password');
      } else {
        setError('An error occurred while logging in');
      }
    }
  };

  return (
    <>
      <div className={styles['blur-bg']}></div>
      <div className={styles.loginpopup}>
      <div className={styles.popupclose}><img className={styles.close_icon} src={Cross_icon} alt="cross_icon" /></div>
        <h1>Login</h1>
        <p>Login to unlock comprehensive MRO solutions!</p>
        <form onSubmit={handleLogin}>
          <div className={`${styles['form-div']} ${activeField === 'email' || inputValues.email ? styles.active : ''} ${error && styles.error}`}>
            <label htmlFor="email">Email*</label>
            <input
              type="email"
              name="email"
              className={styles['input-validate']}
              id="email"
              required
              onFocus={() => handleFocus('email')}
              onBlur={() => handleBlur('email')}
              onChange={handleChange}
              value={inputValues.email}
            />            
            {error && (
            <span className={styles['error-div']}>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 10 10">
                <path id="Path_4483" data-name="Path 4483" d="M14.921,19.414a4.494,4.494,0,1,0-4.494-4.494,4.494,4.494,0,0,0,4.494,4.494Zm0-2.944a.507.507,0,0,1,.358.864h0a.506.506,0,0,1-.715-.715h0a.5.5,0,0,1,.358-.148ZM14.7,12.358c.294,0,.7-.071.7.253v3.228c0,.324-.4.253-.7.253a.253.253,0,0,1-.253-.253V12.611a.253.253,0,0,1,.253-.253Zm3.757,6.1a5,5,0,1,1,0-7.071A5,5,0,0,1,18.456,18.456Z" transform="translate(-9.921 -9.92)" fill="#e02b27" fill-rule="evenodd"/>
              </svg>        
              Enter valid email address              
              {error}
            </span>            
            )}
            
          </div>
          <div className={`${styles['form-div']} ${activeField === 'password' || inputValues.password ? styles.active : ''}`}>
            <label htmlFor="password">Password*</label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              className={styles['input-validate']}
              id="password"              
              onFocus={() => handleFocus('password')}
              onBlur={() => handleBlur('password')}
              onChange={handleChange}
              value={inputValues.password}
            />
            <button type="button" onClick={togglePasswordVisibility} className={styles.eye_button}>
              <img src={showPassword ? Hide_eye_icon : Eye_icon} alt="eye_icon" />
            </button>
          </div>
          <div className={styles['forgot-ps']}><a href="#">Forgot Password?</a></div> 
          <button type="submit" className={styles.login_btn}>Login</button>
        </form>
        <p className={styles['or-signup']}><span>or Sign up with</span></p>
        {/* <button className={styles['continue-google']}><img className={styles.google_icon} src={Google_icon} alt="setting_icon" />Continue with Google</button> */}
        <GoogleOAuthProvider clientId={storedClientId}>
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleError}
              render={(renderProps) => (
                <button
                  type="button" // Add this line
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className={styles['continue-google']}
                >
                  <img
                    className={styles.google_icon}
                    src={Google_icon}
                    alt="Google Icon"
                  />
                  Continue with Google
                </button>
              )}
            />
          </GoogleOAuthProvider>
        <p className={styles['already-account']}>Already have an account? <a href="#">Sign Up</a></p>
      </div>
    </>
  );
};

export default Loginbox;
