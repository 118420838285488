import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_CONTROLLER_URL;

export const fetchAnswerFromQuery = (payload = {}, version = "") => {
  console.log(version)
  const endpoint = version === "v2" ? "/backend/v2/user_chat" : "/controller/v3/user_chat";
  

  // Construct the payload based on the version
  let dataToSend;
  if (version === "" ) {
    // For v3, only include the required fields
    const { query, sessionId, messageId, userId } = payload;
    
    // Validate required fields for v3
    if (!query || !sessionId || !messageId || !userId) {
      return Promise.reject(new Error("Missing required fields for v3 payload."));
    }

    dataToSend = {
      query,
      sessionId,
      messageId,
      userId,
    };
  } else {
    // For other versions (e.g., v2), send the full payload
    dataToSend = payload;
  }

  return axios
    .post(endpoint, dataToSend)
    .then((res) => res.data)
    .catch((err) => err);
};
