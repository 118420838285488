import React, { useState, useEffect, useRef } from "react";
import styles from "../../Components/Forgot/forgot.module.css";
import Cross_icon from "../../assets/icons/svg/cross-icon.svg";
import Password_changed from "../../assets/icons/svg/password-changed.svg";
import Eye_icon from "../../assets/icons/svg/eye-icon.svg";
import Hide_eye_icon from "../../assets/icons/svg/hide-icon.svg";

const Forgotbox = () => {
  const [activeField, setActiveField] = useState(null);
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timeLeft, setTimeLeft] = useState(120);
  const [canResend, setCanResend] = useState(true);
  const inputsRef = useRef([]);
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    new_password: false,
    confirm_new_password: false,
  });

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearInterval(timerId);
    } else {
      setCanResend(false);
    }
  }, [timeLeft]);

  const handleFocus = (field) => setActiveField(field);

  const handleBlur = (field) => {
    if (!inputValues[field]) setActiveField(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleOtpChange = (value, index) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        inputsRef.current[index + 1]?.focus();
      }
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  const resendOtp = () => {
    if (canResend) {
      alert("New OTP sent!");
      setOtp(["", "", "", ""]);
      setTimeLeft(120);
      setCanResend(true);
      inputsRef.current[0]?.focus();
    } else {
      alert("Cannot resend OTP. Please refresh to restart.");
    }
  };

  const verifyOtp = () => {
    const enteredOtp = otp.join("");
    if (enteredOtp.length === 4) {
      if (timeLeft > 0) {
        alert(`Verifying OTP: ${enteredOtp}`);
      } else {
        alert("OTP has expired. Please request a new one.");
      }
    } else {
      alert("Please enter a 4-digit OTP");
    }
  };

  return (
    <>
      <div className={styles["blur-bg"]}></div>
      <div className={styles.forgotpopup}>
        <div className={styles.popupclose}>
          <img className={styles.close_icon} src={Cross_icon} alt="cross_icon" />
        </div>

        {/*******************************Forgot Password Section*********************************/}
        <div className={`${styles.forgot_ps} ${styles["forgot-inner"]}`}>
          <h1>Forgot Password</h1>
          <p>Don't worry! It happens. Please enter the email associated with your account.</p>
          <form>
            <div
              className={`${styles["form-div"]} ${
                activeField === "password" || inputValues.password ? styles.active : ""
              }`}
            >
              <label htmlFor="password">Password*</label>
              <input
                type={passwordVisibility.password ? "text" : "password"}
                name="password"
                className={styles["input-validate"]}
                id="password"
                required
                onFocus={() => handleFocus("password")}
                onBlur={() => handleBlur("password")}
                onChange={handleChange}
                value={inputValues.password}
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility("password")}
                className={styles.eye_button}
              >
                <img
                  src={passwordVisibility.password ? Hide_eye_icon : Eye_icon}
                  alt="eye_icon"
                />
              </button>
            </div>
            <button type="submit">Send Code</button>
          </form>
        </div>
       
        {/*******************************OTP Verification Section*********************************/}
        <div className={`${styles.otp} ${styles["forgot-inner"]}`}>
          <h1>OTP Verification</h1>
          <p>
            We have sent a One Time Password (OTP) to your email
            <br />
            Enter 4-digit code for verification.
          </p>
          <form>
            <span id="timer" className={styles.timer}>
              {timeLeft > 0
                ? `(${Math.floor(timeLeft / 60)}:${String(timeLeft % 60).padStart(2, "0")})`
                : "Code expired"}
            </span>
            <div className={styles["otp-input"]}>
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(e.target.value, index)}
                  onKeyDown={(e) => handleOtpKeyDown(e, index)}
                  ref={(el) => (inputsRef.current[index] = el)}
                  disabled={timeLeft === 0}
                />
              ))}
            </div>
            <div className={styles["resend-text"]}>
              Don't receive the OTP?
              <span
                className={styles["resend-link"]}
                onClick={resendOtp}
                style={{ cursor: canResend ? "pointer" : "not-allowed", color: canResend ? "blue" : "gray" }} >
                Resend
              </span>
            </div>
            <button type="button" onClick={verifyOtp}>Submit</button>
            
          </form>
        </div>

        
        

      </div>
    </>
  );
};

export default Forgotbox;
