import React, { useState, useEffect, useRef } from "react";
import Google_icon from "../../assets/icons/svg/google.svg";
import Eye_icon from "../../assets/icons/svg/eye-icon.svg";
import Hide_eye_icon from "../../assets/icons/svg/hide-icon.svg";
import Cross_icon from "../../assets/icons/svg/cross-icon.svg";
import styles from '../../Components/Signup/signup.module.css';

const Signupbox = () => {
  const [activeField, setActiveField] = useState(null);
  const [inputValues, setInputValues] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timeLeft, setTimeLeft] = useState(120);
  const [canResend, setCanResend] = useState(true);
  const inputsRef = useRef([]);
  const [currentPopup, setCurrentPopup] = useState("signpop"); // Manage current popup
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    new_password: false,
    confirm_new_password: false,
  });

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearInterval(timerId);
    } else {
      setCanResend(false);
    }
  }, [timeLeft]);

  const handleFocus = (field) => {
    setActiveField(field);
  };

  const handleBlur = (field) => {
    if (!inputValues[field]) {
      setActiveField(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignup = (e) => {
    e.preventDefault();
    console.log("Sign Up button clicked");
    setCurrentPopup("otp"); // Switch to OTP popup
    console.log("Current popup set to:", "otp");
  };

  const handleOtpChange = (value, index) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        inputsRef.current[index + 1]?.focus();
      }
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  const resendOtp = () => {
    if (canResend) {
      alert("New OTP sent!");
      setOtp(["", "", "", ""]);
      setTimeLeft(120);
      setCanResend(true);
      inputsRef.current[0]?.focus();
    } else {
      alert("Cannot resend OTP. Please refresh to restart.");
    }
  };

  const verifyOtp = () => {
    const enteredOtp = otp.join("");
    if (enteredOtp.length === 4) {
      if (timeLeft > 0) {
        alert(`Verifying OTP: ${enteredOtp}`);
      } else {
        alert("OTP has expired. Please request a new one.");
      }
    } else {
      alert("Please enter a 4-digit OTP");
    }
  };

  return (
    <>
      <div className={styles['blur-bg']}></div>
      <div className={styles.loginpopup}>
        <div className={styles.popupclose}>
          <img className={styles.close_icon} src={Cross_icon} alt="cross_icon" />
        </div>

        {currentPopup === "signpop" && (
          <div className={styles.signpop}>
            <h1>Sign Up</h1>
            <p>Sign up to unlock comprehensive MRO solutions!</p>
            <form>
              <div className={`${styles['form-div']} ${activeField === 'email' || inputValues.email ? styles.active : ''}`}>
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  name="email"
                  className={styles['input-validate']}
                  id="email"
                  required
                  onFocus={() => handleFocus('email')}
                  onBlur={() => handleBlur('email')}
                  onChange={handleChange}
                  value={inputValues.email}
                />
                <span className={styles['error-div']}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 10 10">
                    <path id="Path_4483" d="M14.921,19.414a4.494,4.494,0,1,0-4.494-4.494,4.494,4.494,0,0,0,4.494,4.494Zm0-2.944a.507.507,0,0,1,.358.864h0a.506.506,0,0,1-.715-.715h0a.5.5,0,0,1,.358-.148ZM14.7,12.358c.294,0,.7-.071.7.253v3.228c0,.324-.4.253-.7.253a.253.253,0,0,1-.253-.253V12.611a.253.253,0,0,1,.253-.253Zm3.757,6.1a5,5,0,1,1,0-7.071A5,5,0,0,1,18.456,18.456Z" transform="translate(-9.921 -9.92)" fill="#e02b27" fillRule="evenodd" />
                  </svg>
                  Enter valid email address
                </span>
              </div>

              <div className={`${styles['form-div']} ${activeField === 'password' || inputValues.password ? styles.active : ''}`}>
                <label htmlFor="password">Password*</label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className={styles['input-validate']}
                  id="password"
                  required
                  onFocus={() => handleFocus('password')}
                  onBlur={() => handleBlur('password')}
                  onChange={handleChange}
                  value={inputValues.password}
                />
                <button type="button" onClick={togglePasswordVisibility} className={styles.eye_button}>
                  <img src={showPassword ? Hide_eye_icon : Eye_icon} alt="eye_icon" />
                </button>
              </div>
              <button type="submit" className={styles.signup_btn} onClick={handleSignup}>Sign Up</button>
            </form>
            <p className={styles['or-signup']}><span>or Sign up with</span></p>
            <button className={styles['continue-google']}>
              <img className={styles.google_icon} src={Google_icon} alt="google_icon" />Continue with Google
            </button>
            <p className={styles['already-account']}>Already have an account? <a href="/">Login</a></p>
          </div>
        )}

        {currentPopup === "otp" && (
          <div className={styles.otp}>
            <h1>OTP Verification</h1>
            <p>
              We have sent a One Time Password (OTP) to your email
              <br />
              Enter 4-digit code for verification.
            </p>
            <form>
              <span id="timer" className={styles.timer}>
                {timeLeft > 0
                  ? `(${Math.floor(timeLeft / 60)}:${String(timeLeft % 60).padStart(2, "0")})`
                  : "Code expired"}
              </span>
              <div className={styles["otp-input"]}>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                    onKeyDown={(e) => handleOtpKeyDown(e, index)}
                    ref={(el) => (inputsRef.current[index] = el)}
                    disabled={timeLeft === 0}
                  />
                ))}
              </div>
              <div className={styles["resend-text"]}>
                Don't receive the OTP?
                <span
                  className={styles["resend-link"]}
                  onClick={resendOtp}
                  style={{ cursor: canResend ? "pointer" : "not-allowed", color: canResend ? "blue" : "gray" }}
                >
                  Resend
                </span>
              </div>
              <button type="button" onClick={verifyOtp} className={styles.otpsubmitbtn}>Submit</button>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default Signupbox;
