export async function fetchRecentChats(email, version) {
    const url = process.env.REACT_APP_BACKEND_BASE_URL
    const versionParam = version === "v2" ? "v2" : version === "v3" ? "v3" : "v1"; // Handle v3, default to v1
    
    try {
        const response = await fetch(url + `/recent-chats?email=${encodeURIComponent(email)}&version=${versionParam}`);
        if (!response.ok) {
            throw new Error("Failed to fetch recent chats");
        }      
      const data = await response.json();      
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  