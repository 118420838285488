const API_URL  = process.env.REACT_APP_BACKEND_BASE_URL;

export const sendFeedback = async (feedbackData) => {
  try {    
    const response = await fetch(API_URL + '/feedback/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feedbackData),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error sending feedback:', error);
    throw error;
  }
};
