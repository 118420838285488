// Sidebar.js
import React, { useEffect, useState } from "react";
import NavigationBar from "./NavigationBar";
import Signup_icon from "../assets/icons/svg/signup_icon.svg";
import Setting_icon from "../assets/icons/svg/setting_icon.svg";
import User_icon from "../assets/icons/svg/user_icon.png";
import { Link } from "react-router-dom";
// import Back_Icon from "../assets/icons/svg/Back_icon_3.svg";
import { clearChats } from "../services/ClearChatUtil";
import { v4 as uuidv4 } from "uuid";
// const [extended, setExtended] = useState(false);

const Sidebar = ({developerMode, toggleDeveloperMode }) => {
  const [navigateToMainPage, setNavigateToMainPage] = useState(false);
  const [SessionId, setSessionId] = useState('');
  const [Conversation, setConversation] = useState('');
  const [email, setEmail] = useState('');

  

  if (navigateToMainPage) {
    // return redirect("/");
    const newSessionId = uuidv4();
    const currentTime = new Date().getTime();
    // localStorage.setItem("sessionId", newSessionId);
    // localStorage.setItem("sessionCreationTime", currentTime.toString());    
    // window.location.reload();
    // return <Navigate to="http://172.16.0.2:3000" replace />;
  }
  const [todayHistory, setTodayHistory] = useState([]);
  const [yesterdayHistory, setYesterdayHistory] = useState([]);

  useEffect(() => {
    fetchTodayHistory();
    fetchYesterdayHistory();
  }, []);
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "email") {
        setEmail(localStorage.getItem("email"));
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []); 
  const fetchTodayHistory = async () => {
    const data = ["Event 1", "Event 2", "Event 3"];
    setTodayHistory(data);
  };

  const fetchYesterdayHistory = async () => {
    const data = ["Event A", "Event B", "Event C"];
    setYesterdayHistory(data);
  };  
  const handleNewChat = (e) => {
    e.preventDefault();
    const storedemail = localStorage.getItem("email");    
    // console.log(storedemail)
    const v2Handled = localStorage.getItem("v2Handled");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("conversation_v1");
    localStorage.removeItem("conversation_v2");
    // localStorage.clear(); 
    localStorage.setItem("email", storedemail);
    localStorage.setItem("v2Handled", v2Handled);
    // await clearChats();
    setNavigateToMainPage(true);
  };

  return (
    <>
    <NavigationBar setSessionId={setSessionId} setConversation={setConversation} />
  

        {
          /*<div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="developerModeSwitch"
            checked={developerMode}
            onChange={toggleDeveloperMode}
          />
          <label className="form-check-label" htmlFor="developerModeSwitch">
            {developerMode ? 'Debug' : 'Debug'}
          </label>
        </div>*/
        }
          <Link href="https://chatmro.com/" onClick={handleNewChat} className="">
    <div className="group mx-md flex flex-none items-center justify-between py-sm pl-md pr-sm font-sans ">
              <div className="line-clamp-1 overflow-hidden text-clip light font-sans text-sm font-medium text-textOff dark:text-textOffDark selection:bg-super/50 selection:text-textMain dark:selection:bg-superDuper/10 dark:selection:text-superDark">
                New Chat
                </div>
              {/* <div className="flex items-center space-x-two light font-sans text-base text-textOff dark:text-textOffDark selection:bg-super/50 selection:text-textMain dark:selection:bg-superDuper/10 dark:selection:text-superDark">
              </div> */}
          </div>
              </Link>
   
      {/* <div className="sidebar">
        <div className="history">Today</div>
        <div className="qwe">
          {todayHistory.map((event, index) => (
            <div className="day" key={index}>
              {event}
            </div>
          ))}
        </div>
        <div className="history">Yesterday</div>
        <div className="qwe">
          {yesterdayHistory.map((event, index) => (
            <div className="day" key={index}>
              {event}
            </div>
          ))}
        </div> */}

        {/* <footer className="horizontal-line">
          <div>
            <hr className="line" />
          </div>
          <div className="Footer">
            <img src={Signup_icon} alt="sign_up icon" />
            <p className="user-detail">User</p>
            <img className="set_icon" src={Setting_icon} alt="setting_icon" />
          </div>
        </footer> */}
{/* 
        <footer>
          <img
            className="user_icon"
            src={User_icon}
            alt="user_icon"
            width={"32px"}
            height={"32px"}
          />
          <span className="user">User</span>
          <img
            className="setting"
            src={Setting_icon}
            alt="setting_icon "
            width={"25px"}
            height={"25px"}
          />
        </footer>
      </div> */}
    </>
  );
};

export default Sidebar;
