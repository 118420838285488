// MainPage.jsx

import Chat from "../Chat";
import Input from "../Input";
import { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import "../Chat/chat.css";
import "../Input/input.css";
import { fetchAnswerFromQuery } from "../../services/Chat";
import OverlayMessage from "../Overlay";
import useLinksInNewTab from "../newTabLink";
import { saveChatToDatabase } from "../../services/sendToDb";
import { incrementMessageCounter } from "../../services/sendIdToDb";
import { updateGuestChatsToLoggedInUser } from "../../services/updateChat";
import { fetchVisitorData } from "../../services/visitorService";
import { useLocation } from "react-router-dom";
import SessionErrorBox from "../Pages/AlertBox";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Loginbox  from "../Login/index";

function MainPage({ developerMode }) {
  const [prompt, setPrompt] = useState("");
  const [conversation, setConversation] = useState([]);
  const [isFetchingAnswer, setIsFetchingAnswer] = useState(false);
  const [initialConversationLoaded, setInitialConversationLoaded] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [email, setEmail] = useState("");
  const [showSessionError, setShowSessionError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loginHandle, setLoginHandle] = useState(localStorage.getItem("login_handle") === "true");
  const [selectedModel, setSelectedModel] = useState("openai_ch");
  const sessionTimeoutSeconds = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
  const location = useLocation();
  const isV2 = location.pathname.startsWith("/v2");
  const isV1 = location.pathname.startsWith("/v1");

  const fpPromise = FingerprintJS.load();

  const inputRef = useRef(null);
  useLinksInNewTab(conversation);

  const [visitorId, setVisitorId] = useState(null);
  useEffect(() => {
    const checkLimitsOnLoad = async () => {
      const canProceed = await checkMessageLimit();
      console.log('Checking on page load');      
      if (!canProceed && !email) {
        console.log('Can not process...........');
        // The limit has been exceeded, show the overlay
        setShowOverlay(true);
      }
    };
  
    if (initialConversationLoaded) {
      checkLimitsOnLoad();
    }
  }, [initialConversationLoaded]);

  useEffect(() => {
    const authToken = getCookie("authToken");
    if (authToken) {
      setShowOverlay(false); // Hide login overlay
    }
  }, []); // Runs once when the component mounts
  
  
  // Get visitorId and set in localStorage
  useEffect(() => {
    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        const visitorId = result.visitorId;
        
        localStorage.setItem("location", result.components.timezone.value);
        localStorage.setItem("visitorId", visitorId);
        setVisitorId(visitorId);
      })
      .catch((error) => console.error("Error fetching fingerprint:", error));
  }, []);

  // Initialize email and loginHandle from localStorage
  useEffect(() => {
    if (localStorage.getItem("login_handle") === null) {
      localStorage.setItem("login_handle", "false");
      setLoginHandle(false);
    } else {
      setLoginHandle(localStorage.getItem("login_handle") === "true");
    }

    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }

    // Handle clearing local storage and resetting state when switching between versions
    if (isV2) {
      const v2Handled = localStorage.getItem("v2Handled");
      if (!v2Handled) {
        // localStorage.clear();
        if (storedEmail) {
          localStorage.setItem("email", storedEmail);
        }
        localStorage.setItem("v2Handled", "true");
        localStorage.setItem("v1Handled", "false");
      }
    } else {
      const v1Handled = localStorage.getItem("v1Handled");
      if (!v1Handled) {
        // localStorage.clear();
        if (storedEmail) {
          localStorage.setItem("email", storedEmail);
        }
        localStorage.setItem("v1Handled", "true");
        localStorage.setItem("v2Handled", "false");
      }
    }
  }, [isV2]);

  // Key for storing conversations based on version
  const versionKey = isV2 ? "conversation_v2" : "conversation_v1";

  useEffect(() => {
    checkSessionExpiration();

    // Set interval to check session expiration every minute
    const sessionCheckInterval = setInterval(checkSessionExpiration, 2000);

    return () => clearInterval(sessionCheckInterval); // Clear the interval on component unmount
  }, []);

  // Update showOverlay whenever loginHandle changes
  useEffect(() => {
    setShowOverlay(loginHandle);
  }, [loginHandle]);

  const checkSessionExpiration = () => {
    const storedSessionId = localStorage.getItem("sessionId");
    const sessionCreationTime = localStorage.getItem("sessionCreationTime");
    const now = Date.now();

    if (
      !sessionCreationTime || !storedSessionId
    ) {
      handleSessionExpiration();
    } else {
      
      if (storedSessionId) setSessionId(storedSessionId);
      const storedConversation = localStorage.getItem(versionKey);
      if (storedConversation) {
        setConversation(
          JSON.parse(storedConversation).map((chat) => ({
            ...chat,
            isAnswerFromThisChat: false,
          }))
        );
      } 
    }
    setInitialConversationLoaded(true);
  };

  const getCurrentTimestamp = () => {
    // Get the current Unix timestamp in seconds
    const timestamp = Math.floor(Date.now() / 1000);
    return timestamp;
  };
  const handleSessionExpiration = () => {
    if (loginHandle) {
      // If the user needs to log in, show the overlay and do not create a new session
      setShowOverlay(true);
      // setEmail(null);
      // setSessionId(null);
      return;
    }

    const newSessionId = getCurrentTimestamp();
    // const newSessionId = uuidv4();
    const currentTime = Date.now();
    let storedEmail = email || localStorage.getItem("email");
  
    if (!storedEmail) {
      storedEmail = "guest@user.com";
      localStorage.setItem("email", storedEmail);
    } else {
      localStorage.setItem("email", storedEmail);
    }
  
    localStorage.setItem("sessionId", newSessionId);
    localStorage.setItem("sessionCreationTime", currentTime.toString());
  
    setEmail(storedEmail);
    setSessionId(newSessionId);
    displayAlert();
    // Avoid unnecessary reloads
    inputRef.current?.focus();
    setConversation([]);
  };
  const deleteCookie = (name) => {
    document.cookie = `${name}=; Max-Age=0; path=/; domain=${window.location.hostname};`;
  };
  
  const handleLogout = () => {
    localStorage.setItem("login_handle", "true");
    setLoginHandle(true);
    localStorage.removeItem("email");
    localStorage.removeItem("sessionId");
    setEmail("");
    setSessionId("");
    deleteCookie('authToken')
    deleteCookie('ACCOUNT_CHOOSER')
    window.location.reload();
    setShowOverlay(true);
  };

  const checkMessageLimit = async () => {  
    let sessionCount;
    let messageCount;
    try {
        const data = await fetchVisitorData(localStorage.getItem('visitorId'));        
        sessionCount = data.session_count || 0;
        messageCount = data.total_message_count || 0;
        localStorage.setItem('messageCount', messageCount);
        localStorage.setItem('sessionCount', sessionCount);
      } catch (error) {
        console.error("Error fetching session count:", error);
        sessionCount = 0; 
        messageCount = 0;
      }  
    const messageLimit = 10; 
    const sessionLimit = 10;
  
    if (localStorage.getItem('email') === 'guest@user.com' && (messageCount >= messageLimit || sessionCount >= sessionLimit)) {    
      localStorage.setItem("login_handle", "true");
      setLoginHandle(true);
      setShowOverlay(true);
      localStorage.removeItem('email');
      // localStorage.removeItem('sessionId');      
      return false;
    }
    return true;
  };
  

  const displayAlert = () => {
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 100);
  };

  const handleSaveChat = async (newConversation) => {
    // Wait for the typing effect to finish
    await new Promise((resolve) => setTimeout(resolve, 4000)); // Adjust the timeout as needed

    const maxRetries = 3; 
    let attempts = 0;
    let savedSuccessfully = false;

    while (attempts < maxRetries) {
      try {
        const response = await saveChatToDatabase(
          newConversation[newConversation.length - 1]
        );
        if (response && response.status === 500) {
          attempts++;
          console.log('Tring to save in attempt',attempts)
          // Optionally wait before retrying
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait before retrying
        } else {
          savedSuccessfully = true; // Save was successful
          break; // Exit the loop if saved successfully
        }
      } catch (error) {
        console.error("Error in saving chat:", error);
        attempts++;
        // Optionally wait before retrying
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait before retrying
      }
    }

    if (!savedSuccessfully) {
      setShowSessionError(true); // Show error after retries fail
    }
  };

  const getAnswer = async () => {
    const storedSessionId = localStorage.getItem("sessionId");
    let storedEmail = localStorage.getItem("email");
    
    if (!storedSessionId || !storedEmail) {
      console.warn("One or more items are missing from localStorage.");
      setShowSessionError(true);
      setTimeout(() => setShowSessionError(false), 5000); // Hide the error after 5 seconds
      // localStorage.clear();
      setShowOverlay(true); // Show overlay if not already shown
      return;
    }
    
    setIsFetchingAnswer(true);
    const canProceed = await checkMessageLimit();
    if (!canProceed) {
      setIsFetchingAnswer(false)
      return;
    }

    try {
      const response = await fetchAnswerFromQuery(
        {
          messageId: Date.now().toString(),          
          userId: storedEmail,
          query: prompt,
          email: storedEmail,
          sessionId,
          conversation,
          model: selectedModel,
          version: isV2 ? "v2" : "v1",
        },
        isV2 ? "v2" : ""
      );
      const newConversation = [
        ...conversation.map((chat) => ({ ...chat, isAnswerFromThisChat: false })),
        {
          id: Date.now().toString(),
          email: storedEmail,
          user: "User",
          prompt,
          sessionId,
          answer: response[1],
          backendAnswer: response[0],
          isAnswerFromThisChat: true,
          version : isV2 ? "v2" : isV1 ? "v1" : "v3",

        },
      ];
      setConversation(newConversation);
      localStorage.setItem(versionKey, JSON.stringify(newConversation));

      setIsFetchingAnswer(false);
      setPrompt("");
      handleSaveChat(newConversation);      
      updateGuestChatsToLoggedInUser(sessionId, storedEmail);
    } catch (err) {
      console.error(err);
    } finally {
      setIsFetchingAnswer(false);
      setPrompt("");
      try {        
        await incrementMessageCounter({
          sessionId,
          email: storedEmail,
          fingerprint: localStorage.getItem("visitorId"),
          version: isV2 ? "v2" : "v1",
        });
        const data = await fetchVisitorData(localStorage.getItem("visitorId"));
        const updatedMessageCount = data.total_message_count || 0;
        const updatedSessionCount = data.session_count || 0;
        localStorage.setItem("messageCount", updatedMessageCount);
        localStorage.setItem("sessionCount", updatedSessionCount);
        
        inputRef.current?.focus();
        // const messageLimit = 5; // Adjust as needed
        // const sessionLimit = 10; // Adjust as needed

        // if (updatedMessageCount >= messageLimit || updatedSessionCount >= sessionLimit && localStorage.getItem('email') === 'guest@user.com') {          
        //   localStorage.setItem("login_handle", "true");
        //   setLoginHandle(true);
        //   setShowOverlay(true);
              
        //   localStorage.removeItem('email');
        //   localStorage.removeItem('sessionId');        
        // }
      } catch (error) {
        console.error("Error fetching updated counts:", error);
      }
      // try {
      //   console.log('updating the visitor count..... ')
      //   await incrementMessageCounter({
      //     sessionId,
      //     email: storedEmail,
      //     fingerprint: localStorage.getItem("visitorId"),
      //     version: isV2 ? "v2" : "v1",
      //   });

      //   localStorage.setItem("messageCount", response.data.messageCount);
        // if (response && response.data && response.data.messageCount >= 10) {
        //   alert("Please login to continue");
        //   handleLogout();
        // }
      // } catch (error) {
      //   console.error("Error incrementing message count:", error);
      // }
    }
  };

  const handlePasswordSubmit = (email) => {    
    const authToken = getCookie("authToken");
    if (authToken) {
        setShowOverlay(false);        
      } 
    if (!sessionId) {
        const newSessionId = getCurrentTimestamp();
        // const newSessionId = uuidv4();
        localStorage.setItem("sessionId", newSessionId);  
        setSessionId(newSessionId);
      }
    updateGuestChatsToLoggedInUser(sessionId, email);
    localStorage.setItem("login_handle", "false");
    setLoginHandle(false);
    localStorage.setItem("email", email);
    setEmail(email);
    // Do not update sessionId
    displayAlert();
    setShowOverlay(false);
    inputRef.current?.focus();
  };
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
  };
  
  

  return (
    <div className="inner-box">
      <div className="model-dropdown">
        {/*
      <select
          value={selectedModel}
          onChange={(e) => setSelectedModel(e.target.value)}
        >
          <option value="openai_ch">GPT-4o</option>
          <option value="openai_mini">GPT-4omini</option>
          <option value="chatgpt">GPT-3.5</option>
          <option value="gemma2-9b-it">gemma2-9b-it</option>
          <option value="llama3-70b-8192">llama3-70b-8192</option>
          <option value="llama3-8b-8192">llama3-8b-8192</option>
          <option value="llama3-groq-70b-8192-tool-use-preview">
            llama3-groq-70b-8192-tool-use-preview
          </option>
          <option value="mixtral-8x7b-32768">mixtral-8x7b-32768</option>
        </select>*/ 
        }      
        <div onClick={handleLogout} className="logout_button">
        <div>{(localStorage.getItem('email') || '').split('@')[0] || 'Guest'}</div>
        <div className="flex items-center justify-center text-token-text-secondary h-5 w-5"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 shrink-0"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 4C5.44772 4 5 4.44772 5 5V19C5 19.5523 5.44772 20 6 20H10C10.5523 20 11 20.4477 11 21C11 21.5523 10.5523 22 10 22H6C4.34315 22 3 20.6569 3 19V5C3 3.34315 4.34315 2 6 2H10C10.5523 2 11 2.44772 11 3C11 3.55228 10.5523 4 10 4H6ZM15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289L20.7071 11.2929C21.0976 11.6834 21.0976 12.3166 20.7071 12.7071L16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071C14.9024 16.3166 14.9024 15.6834 15.2929 15.2929L17.5858 13H11C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11H17.5858L15.2929 8.70711C14.9024 8.31658 14.9024 7.68342 15.2929 7.29289Z" fill="currentColor"></path></svg></div>          
        </div>
      </div>

      <Chat
        conversation={conversation}
        isFetchingAnswer={isFetchingAnswer}
        currentPrompt={prompt}
        developerMode={developerMode}
      />
      {initialConversationLoaded && !showOverlay && (
        // Disable input when overlay is shown
        <Input
          ref={inputRef}
          prompt={prompt}
          isFetchingAnswer={isFetchingAnswer}
          onChange={(e) => setPrompt(e.target.value)}
          handleSubmit={() => {
            if (prompt.length > 0) getAnswer();
          }}
        />
      )}

      {showAlert && <div className="alert-box">New session started</div>}
      {showOverlay && <Loginbox onPasswordSubmit={handlePasswordSubmit} />}
      {showSessionError && (
        <SessionErrorBox message="Something went wrong. Please try logging in again." />
      )}
    </div>
  );
}

export default MainPage;
